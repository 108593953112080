<template>
  <v-container fluid>
    <v-layout column fill-height wrap>
      <v-container
        style="width: 90%; max-width: 450px; max-height=90vh;"
        class="mx-auto"
        >{{ $t('error.tokenCheckError') }}</v-container
      >
    </v-layout>
  </v-container>
</template>

<script>
export default {
  name: 'Error',
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
